<template>
	<editor
		v-if="display"
		:id="uuid"
		v-model="model"
		:init="initSettings"
		:disabled="disabled"
		tinymce-script-src="/tinymce/tinymce.js"
		@onChange="fireEvent"
		@onCopy="fireEvent"
		@onCut="fireEvent"
		@onPaste="fireEvent"
		@onRedo="fireEvent"
		@onUndo="fireEvent"
	></editor>
</template>

<script>
import { mapState } from 'vuex'
import { v4 as uuid } from 'uuid'
import Editor from '@tinymce/tinymce-vue'

export default {
	name: 'WRichTextEditor',
	components: {
		editor: Editor
	},
	props: {
		/**
		 * ID of editor
		 * As default unique ID is generated
		 *
		 * @type {String}
		 */
		uuid: {
			required: false,
			type: String,
			default: (() => {
				return uuid()
			})()
		},
		/**
		 * Editor value
		 * @type {String}
		 */
		value: {
			default: () => '',
			required: false,
			type: String
		},
		/**
		 * Editor mode (minimal, advanced, normal, minimal)
		 * @type {String}
		 */
		mode: {
			required: false,
			type: String,
			default: 'minimal'
		},
		/**
		 * Editor skin, as default oxide is used
		 * @type {String}
		 */
		skin: {
			type: String,
			default: 'oxide'
		},
		/**
		 * Editor content skin, as default use content of skin
		 * @type {String|null}
		 */
		skinContent: {
			type: String,
			default: null
		},
		/**
		 * Data to put as default data is editor empty
		 * @type {String}
		 */
		placeholder: {
			type: String,
			default: ''
		},
		/**
		 * Editor toolbar to create
		 * @type {String|null}
		 */
		toolbar: {
			type: String,
			default: null
		},
		/**
		 * True if toolbar is display sticky
		 * @type {Boolean}
		 */
		toolbarSticky: {
			type: Boolean,
			default: true
		},
		/**
		 * Editor height
		 * @type {String}
		 */
		height: {
			type: String,
			default: '50vh'
		},
		/**
		 * True if editor is disable
		 * @type {Boolean}
		 */
		disabled: {
			type: Boolean,
			default: false
		},
		/**
		 * Editor menu bar to create
		 * @type {String}
		 */
		menubar: {
			type: String,
			default: ''
		},
		/**
		 * Editor context menu to create
		 * @type {String}
		 */
		contextMenu: {
			type: String,
			default: ''
		},
		/**
		 * Editor quick bar selection toolbar to create
		 * @type {String}
		 */
		quickBarsSelectionToolbar: {
			type: String,
			default: ''
		},
		/**
		 * List of plugins to use on editor
		 * @type {String}
		 */
		plugins: {
			type: String,
			default: () => {
				return 'print preview powerpaste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'
			}
		},
		/**
		 * Editor language UI to use
		 * As default is user browser language
		 * At this moment only EN and FR is supported
		 *
		 * @type {String}
		 */
		language: {
			type: String,
			default: (() => {
				return navigator.language
			})()
		}
	},
	data: function () {
		return {
			display: false
		}
	},
	computed: {
		...mapState({
			dark: state => state.user.darkMode
		}),
		/**
		 * Define context menu elements based on editor mode choice
		 * @return {String}
		 */
		calculateContextMenu: function () {
			let r = ''
			if (this.contextMenu !== null) {
				r = this.contextMenu
			} else {
				if (this.mode === 'full') {
					r = 'link image imagetools table'
				}
			}
			return r
		},
		/**
		 * Define quick bar elements on text selection based on editor mode choice
		 * @return {String}
		 */
		calculateQuickBarsSelectionToolbar: function () {
			let r = ''
			if (this.quickBarsSelectionToolbar !== null) {
				r = this.quickBarsSelectionToolbar
			} else {
				if (this.mode === 'full') {
					r = 'bold italic | quicklink h2 h3 blockquote quickimage quicktable'
				}
			}
			return r
		},
		/**
		 * Define toolbar elements based on editor mode choice
		 * @return {String}
		 */
		calculateToolbar: function () {
			let r = ''
			if (this.toolbar !== null) {
				r = this.toolbar
			} else {
				switch (this.mode) {
					case 'advanced':
					case 'full':
						r =
							'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | image link anchor | ltr rtl | help'
						break
					case 'minimal':
						r =
							'undo redo | formatselect |  bold italic backcolor | alignleft aligncenter  alignright alignjustify | bullist numlist outdent indent |  removeformat'
						break
					case 'normal':
						r =
							'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons'
						break
				}
			}

			return r
		},
		/**
		 * Define menu bar elements based on editor mode choice
		 * @return {String}
		 */
		calculateMenuBar: function () {
			if (this.mode === 'full') {
				return 'file edit view insert format tools table help'
			} else if (this.menubar.length === 0) {
				return false
			} else {
				return this.menubar
			}
		},
		/**
		 * Define init settings for TinyMCE editor
		 * @return {Object}
		 */
		initSettings: function () {
			let data = {}
			data['height'] = this.height
			data['plugins'] = this.plugins
			data['menubar'] = this.calculateMenuBar
			data['toolbar'] = this.calculateToolbar
			data['toolbar_sticky'] = this.toolbarSticky
			data['skin'] = this.skin + (this.dark ? '-dark' : '')
			data['content_css'] = (this.skinContent !== null ? this.skinContent + (this.dark ? '-' : '') : '') + (this.dark ? 'dark' : '')
			data['language'] = this.language.replace('-', '_')
			data['powerpaste_word_import'] = 'merge'
			data['powerpaste_html_import'] = 'merge'
			data['toolbar_mode'] = 'sliding'
			data['contextmenu'] = this.calculateContextMenu
			data['quickbars_selection_toolbar'] = this.calculateQuickBarsSelectionToolbar
			data['content_style'] = 'body {font-size: 12pt;}'
			data['paste_postprocess'] = this.pasteHandler
			data['placeholder'] = this.placeholder

			return data
		},
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		}
	},
	watch: {
		placeholder: function () {
			this.reloadEditor()
		},
		/**
		 * Watch normal or dark mode changes
		 */
		dark: function () {
			this.reloadEditor()
		}
	},
	mounted () {
		this.$nextTick(() => {
			this.display = true
		})
	},
	methods: {
		/**
		 * Return true if TinyMCE data contains TinyMCE optional helpers
		 * @return {Boolean}
		 */
		needCleanInTinyMCE () {
			let el = document.createElement('html')
			el.innerHTML = this.model

			let k = el.querySelectorAll('[class^="ephox-sloth-bin_"]')
			return k.length >= 1
		},
		/**
		 * Clean TinyMCE optional helpers in content data
		 * @return void
		 */
		cleanTinyMCE () {
			let el = document.createElement('html')
			el.innerHTML = this.model

			let k = el.querySelectorAll('[class^="ephox-sloth-bin_"]')
			k.forEach(function (item) {
				item.remove()
			})
			return el.getElementsByTagName('body')[0].innerHTML
		},
		/**
		 * Post past handler to make something
		 * @return void
		 */
		pasteHandler () {
			// let el = document.createElement('html')
			// el.innerHTML = this.model
			//
			// TODO : Implement later Webdings and Wingdings changes : https://www.npmjs.com/package/awesome-dings
		},
		/**
		 * Get TinyMCE editor object
		 * @return {*}
		 */
		getEditor () {
			return window.tinyMCE.get(this.uuid)
		},
		/**
		 * Force reload of TinyMCE editor
		 * @return void
		 */
		reloadEditor () {
			this.display = false
			setTimeout(() => {
				this.display = true
			}, 50)
		},
		/**
		 * Return cleaned editor content
		 * @return {String}
		 */
		getSanitizedContent () {
			if (this.needCleanInTinyMCE()) {
				return this.cleanTinyMCE()
			}
			return this.model
		},
		/**
		 * Clean editor content if is need
		 */
		sanitize () {
			let r = this.getSanitizedContent()
			if (r !== this.model) {
				this.model = r
			}
		},
		/**
		 * Execute a callback on significant fire event
		 * @param event
		 */
		fireEvent (event) {
			this.$emit('change', event)
		}
	}
}
</script>

<style scoped></style>
